import React from 'react';

function HourglassUpdateLog() {
  return (
    <div className='w-full h-full flex items-center justify-center font-serif bg-sky-800'>
      <div className='h-screen overflow-scroll scrollbar-thin w-full md:w-1/2 pt-8 px-6 bg-gray-100'>

        <center className='text-3xl'>
          Miscellaneous Notes
        </center> <br/>

        <p className='text-justify'>
          The following is a series of miscellaneous notes, technical or otherwise.
        </p><br/>

        <p className='text-justify'>
          I am by no means a front-end developer, so I'm sure many of you will stumble upon bugs or elements whose design leaves much to be desired (this is especially true on mobile, and it is recommended that you view this site on a computer or tablet). If you would like to notify me about these or have any suggestions for improvements, feel free to email me at wcui@mit.edu.
        </p><br/>

        <p className='text-justify'>
          If a certain part of the website is broken, please try accessing it again in a few hours or the next day, when I will hopefully have had an opportunity to fix it. In the case of longer outages or periods of maintenance, I will try to detail what is not working and when it should be back up in the updates section below.
        </p><br/>

        <p className='text-justify'>
          If you know me personally and would like to chat about this project or the themes it explores, feel free to reach out either online or in-person. If we haven't met in-person and you'd like to contact me, a good way is through email at wcui@mit.edu.
        </p>
        <br/><br/><br/><br/>


        <center className='text-3xl'>
          Update Log
        </center> <br/>

        <p className='text-justify font-bold'>
          24/10/13, 16:50.
        </p>
        <p className='text-justify'>
          Audio + transcriptions archive added. All systems functioning as of 16:55.
        </p>

        <p className='text-justify font-bold'>
          24/06/29, 01:48. 
        </p>
        <p className='text-justify'>
          Server backend not responding, rebooted. All systems functioning as of 01:48.
        </p>

        <p className='text-justify font-bold'>
          24/06/29, 01:29. 
        </p>
        <p className='text-justify'>
          Power outage in my building from 22:48-00:10 made Livestream and Archive inaccessible. All systems functioning as of 00:17.
        </p>

        <p className='text-justify font-bold'>
          24/06/25, 06:15. 
        </p>
        <p className='text-justify'>
          This marks the completion of the technical component of this phase of my project, rendering it ready for release. A (hopefully) functioning version of wtcui.ca/hourglass is up with a livestream, archive, and activity log/navigation. The goal is to publicize the project today, starting with the Youtube video and posts on various social media. Hopefully I'll be able to let this run on its own for a few months and focus on physics, but that's almost surely wishful thinking.
        </p>

        <br/><br/><br/><br/><br/><br/>
      </div>
    </div>
  );
}

export default HourglassUpdateLog;
