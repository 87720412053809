import React, { useState, useEffect, useContext } from 'react';

import { NavLink } from 'react-router-dom';

import TimelineComponent from './timeline'
import SmallCalendar from './calendar'

function HourglassNavigation() {

  const baseUrl = 'https://wtcui.ca/api'

  const [intervals, setIntervals] = useState([]);
  const parentCategories = ["Work-Physics", "Work-Art", "Recreation", "Maintenance"]

  // load events from backend into intervals
  function mapEventsToIntervals(events) {
    const now = new Date().toISOString(); // Current time in ISO format
    const eventDict = {};
  
    // Group events by name and type
    events.forEach(event => {
      if (!eventDict[event.name]) {
        eventDict[event.name] = { start: [], end: [] };
      }

      var eventTimestamp = new Date(event.time).toISOString();
      if (event.status === true) {
        eventDict[event.name].start.push(eventTimestamp);
      } else if (event.status === false) {
        eventDict[event.name].end.push(eventTimestamp);
      }
    });

    // Create intervals array
    const intervals = [];
    for (const [name, times] of Object.entries(eventDict)) {
      const { start, end } = times;
      const length = Math.max(start.length, end.length);
      for (let i = 0; i < length; i++) {
        const interval = {
          name: name,
          start: start[i],
          end: end[i] ? end[i] : now,
          color: name.includes("Flag:") ? "red" : parentCategories.includes(name) ? "blue" : "gray"
        };
        intervals.push(interval);
      }
    }

    return intervals;
  }

  async function onLoad() {
    const res = await fetch(baseUrl + '/loadEvents',
    {
        method: 'GET',
    }).then((response) => response.json());

    // console.log(res.data)
    setIntervals(mapEventsToIntervals(res.data))
  }
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      onLoad();
    }
    return () => { ignore = true; }
  },[]);

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");


  // set up variables needed for timeline
  function getDayOffsetISO() {
    const date = new Date();
  
    // Subtract 20 hours (20 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    date.setTime(date.getTime() - (20 * 60 * 60 * 1000));
  
    // Convert the Date object to an ISO string
    return date.toISOString();
  }

  const [timelineDate, setTimelineDate] = useState(getDayOffsetISO());
  const handleCalendarDate = (data) => {
    setSelectedDate(data);
    setTimelineDate(data);
  }


  let [recordingUrls, setRecordingUrls] = useState(["",""]);
  const [showCreateLinkModel, setShowCreateLinkModel] = useState(false);

  const getRecording = async (e) => {
    e.preventDefault();

    if (selectedDate === "" || selectedTime === "") {
      console.log("Please input a valid date and time.");
      return;
    }

    console.log(selectedDate, selectedTime)
    const response = await fetch(
      baseUrl + '/getRecording', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: {
          date: selectedDate.split('T')[0],
          time: selectedTime
        }})
    })

    const result = await response.json();
    setRecordingUrls([result.fileCam1Url, result.fileCam2Url]);

    if (result.fileCam1Url !== "" || result.fileCam2Url !== "") {
      setShowCreateLinkModel(true)
    }

    console.log(result.fileCam1Url, result.fileCam2Url);
  }

  function CreateLinkModel() {
    return(
      <div className="fixed right-1/2 top-1/2 transform translate-x-1/2 -translate-y-1/2 border-2 w-11/12 md:w-64 bg-white rounded-lg shadow-2xl">
        <header className="h-10 px-4 py-2 flex justify-between items-center bg-gray-100">
          <span className="text-gray-700 pr-10">
            Video Link:
          </span>

          <button
              type="button" //to remove annoying console message
              onClick={() => setShowCreateLinkModel(false)}
          >
              <span className="text-gray-400">
                  X
              </span>
          </button>
        </header>

        <div className="p-3">
          <div>
            {recordingUrls[0].slice(-4) === ".mp4" ? (
              <a href={recordingUrls[0]} target="_blank" className='text-blue-600'>Camera 1</a>
            ) : (
              <p>Camera 1: File not found</p>
            )}
          </div>
          <div>
            {recordingUrls[1].slice(-4) === ".mp4" ? (
              <a href={recordingUrls[1]} target="_blank" className='text-blue-600'>Camera 2</a>
            ) : (
              <p>Camera 2: File not found</p>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full h-full flex items-center justify-center font-serif bg-sky-800'>
      <div className='h-screen overflow-scroll scrollbar-thin w-full md:w-5/6 pt-8 pb-20 px-6 bg-gray-100'>
        <div class="flex items-center justify-center">
          <a className='text-blue-600 text-2xl' href='//wtcui.ca/hourglass/live'>Live Stream</a> 
        </div>
        <br/>
        <div class="flex items-center justify-center">
          <a className='text-blue-600 text-2xl' href='//wtcui.ca/recordings/hourglass_mount'>Archive: Video</a> 
        </div>
        <div class="flex items-center justify-center">
          <a className='text-blue-600 text-2xl' href='//wtcui.ca/recordings/audio_mount'>Archive: Audio + Transcription</a> 
        </div>
        <br/><br/>

        For technical or miscellaneous notes and updates, please refer to <NavLink to='/Hourglass/updates' className='text-blue-600' >this</NavLink> page. <br/><br/>

        <p className="text-2xl font-bold">Navigation Menu</p>
        <p className='p-2'>To access a recording, select the a date on the calendar, the time of this date, and press `Confirm'. Refer to "Archive" for a complete set of recordings, under /Hourglass-1 and /Hourglass-2. </p>

        <div className="bg-blue-50 p-2">
          <div className="flex flex-col md:flex-row">
            <div className='w-full md:w-1/2'>
              <p>Select Date:</p>

              <div className="pl-20 md:pl-0">
                <div className="w-64 -mt-4">
                  <SmallCalendar onData={handleCalendarDate} />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 mt-4 md:mt-0">
              <p>Select Time:</p>
              <input
                  type="time"
                  placeholder="Start time"
                  value={selectedTime}
                  className="text-gray-600 border-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500 w-1/3 "
                  onChange={(e) => setSelectedTime(e.target.value)}
              />
              <p className='pt-4 md:pt-32'>Selected Date: {selectedDate ? new Date(selectedDate).toISOString().split('T')[0] + ",": ""} {selectedTime}</p>
              <button
                  type="submit"
                  onClick={getRecording}
                  className="bg-blue-500 hover:bg-blue-600 w-20 py-2 rounded text-white"
              >
                  Go
              </button>
            </div>
            
          </div>
        </div>

        <p className="text-2xl font-bold">The Hours</p>
        <p className='p-2'>Note: the activity log dates back to only 2024/06/13, but the video archive dates back to 2023/10/24.</p>
        <TimelineComponent
          events = {intervals}
          selectedDate = {timelineDate}
        />

        <React.Fragment>
          {showCreateLinkModel && <CreateLinkModel />}
        </React.Fragment>

      </div>
    </div>
  );
}


export default HourglassNavigation;
