import React from 'react';
import { NavLink } from 'react-router-dom';

function Hourglass() {
  return (
    <div className='w-full h-full flex items-center justify-center font-serif bg-sky-800'>
      <div className='h-screen overflow-scroll scrollbar-thin w-full md:w-1/2 pt-8 px-6 bg-gray-100'>

        <center className='text-3xl'>
          The Hours
        </center> <br/>
        <center className='text-xl'>
          <span className='italic'> or, </span> <br/><br/>
          狂人日记 <br/><br/>
        </center>

        <br/>
        <div className="italic">
          To Jim,
          <br/>
          il miglior physicus.
        </div>
        <br/>

        <div className='w-full flex justify-end'>
          <div>
            <blockquote className="italic text-gray-900 pl-6">
              For I have known them all already, known them all: <br/>
              Have known the evenings, mornings, afternoons, <br/>
              I have measured out my life with coffee spoons; <br/>
              I know the voices dying with a dying fall <br/>
              Beneath the music from a farther room. <br/>
              So how should I presume? <br/>
            </blockquote>
          </div>
        </div>
        <br/>

        Hello, <br/><br/>

        My name is Wentao. <br/><br/>

        <p className='text-justify'>
          I am a third year PhD candidate working in quantum gravity, and so I spend most of my time thinking about physics. This project is an attempt at something else, a foray into art. In the following link you can access the contents of my life. Not all of it, by any means, just the portion I spend in the confines of my room.
        </p>
        <br/>

        <p className='text-justify'>
          But I spend a great deal of time enclosed by these walls, it is after all quite a nice room. I work and sleep in my room. I talk to friends and communities in my room. I rejoice and I despair, or as is more common plod through the days flying by, bearing away with them the value of a human life as I am left ensnared in its trappings. I eat and shit and jerk off in my room, I consume and doom, hope and cope and rope. For the past two years and perhaps the next three, here is where I will grow and decay, and this room will soak up my rot.
        </p>
        <br/>

        <p className='text-justify'>
          Mounted here are a pair of cameras, whose footage is saved to a repository. You may access its entirety in the following link.
        </p>
        <br/>

        <p className='text-justify'>
          If you know me in person, I encourage you to consider carefully before watching the footage. Its contents includes material that is not safe for work, and should only be viewed by mature audiences. It includes material that may lead you to view me differently, that may irrevocably discolor all our future interactions. If that does not faze you, then welcome.
        </p>
        <br/>

        <p className='text-justify'>
          You are free to do with any of the content as you see fit. I do not claim ownership to any of it, no more than I believe my life is any part mine own.
        </p>
        
        <br/><hr/><br/>

        <div className="flex justify-center items-center">
          <div className="italic">
            In the desert <br/>
            I saw a creature naked, bestial, <br/>
            Who, squatting upon the ground, <br/>
            Held his heart in his hands, <br/>
            And ate of it. <br/>
            I said, "Is it good, friend?" <br/>
            "It is bitter—bitter," he answered;<br/><br/>

            "But I like it <br/>
            Because it is bitter <br/>
            And because it is my heart."<br/><br/>
          </div>
        </div>

        <p className='text-justify'>
          It's been two years since this project was conceived, initially as a self-accountability measure. I wanted to shine a bright light at the shadows into which I retreated from the lurching existential dread that was doing and failing at research. I wanted to render unequivocable how I squandered my time, in an effort to bridge the widening gap between who I was and who I wished to be. Back then I hadn't a thought of releasing the footage; I would have balked at the very idea, and I still do today.
        </p>
        <br/>

        <p className='text-justify'>
          Since then, I realized that this made public could be used to explore several themes very close to my heart. In this sense what you see today is a proof of concept of a larger work in development. I'd like people to think about accountability and authenticity in the era of the internet, about intimacy and privacy, memory and the <a className='text-blue-800' href='//devonzuegel.com/the-truth-of-fact-the-truth-of-feeling-by-ted-chiang-subterranean-press' target='_blank'>narratives</a>  we tell ourselves about ourselves. But above all, this is to unflinchingly capture the Sisyphean attempt to answer the following question, doomed to failure before it scarce began, yet necessary all the same: How does one lead a life, so as not to make a mockery of one's values<a className='text-blue-600' href='//en.wikipedia.org/wiki/List_of_political_self-immolations#2020s' target='_blank'>?</a>
        </p>
        <br/>

        <p className='text-justify'>
          For the past few days I've had my finger hovering over the switch that would take this public, vacillating between surefire social suicide and defering it by another week as all my deep-set convictions melt away in the face of anxiety and neuroticism.
        </p>
        <br/>

        But to channel the strength of my favorite poet, <br/><br/>

        <blockquote className="italic text-gray-900 dark:text-white pl-6">
          I am no prophet—and here’s no great matter. <br/><br/>
        </blockquote>

        So let fall the silks, the velvets, the gossamer curtain of decorum, and see me as I am. <br/><br/><br/>

        <center>
          This place is not a place of dignity. <br/><br/>

          No highly esteemed deed is commemorated here. <br/><br/>

          Nothing of objective value is here. <br/><br/>

          What is here is likely banal and repulsive to you. <br/><br/>

          Let the message be what you take away. <br/><br/><br/>
        </center>


        <div className='w-full flex justify-end'>
          <div>
            Sincerely, <br/>
            Wentao Cui <br/>
            22 February, 2024 <br/>
            235 Albany St<a className='text-blue-400' href='//www.youtube.com/watch?v=2-rXuvb6Za0&ab_channel=jREG' target='_blank'>.</a> Unit 3071 <br/> 
            Cambridge, MA, 02139 <br/><br/>
          </div>
        </div>
        <center>
          <NavLink to='/Hourglass/navigation' className='text-blue-600' >Ecce Homo</NavLink>
          {/* <a className='text-blue-600' href='//wtcui.ca/recordings'>Enter</a> */}
        </center>
        <br/><br/><br/><br/><br/><br/>
      </div>
    </div>
  );
}


export default Hourglass;